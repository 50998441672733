<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-dialog v-model="updateStatusDialog" persistent max-width="500px">
            <v-card>
              <v-card-title>Update Application Status</v-card-title>
              <v-card-subtitle>{{ updateInfo.desc }}</v-card-subtitle>
              <v-card-text>
                <v-select
                  v-model="infoStatus"
                  :items="infoStatusSelect"
                  item-value="name"
                >
                  <template v-slot:item="{ item }">
                    <v-icon>{{ infoStatusList[item.name].icon }}</v-icon>
                    {{ item.desc }}
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                    >{{ item.desc }}
                  </template>
                </v-select>
                <v-card-actions class="justify-center">
                  <v-btn
                    type="submit"
                    color="primary"
                    @click="handleStatusUpdate()"
                  >
                    Confirm
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="grey"
                    @click="closeUpdateStatus()"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="infoHeaders"
            :items="applications"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar color="primary" dark>
                <v-toolbar-title> Application List </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
              <v-row class="ma-2" dense align="center" justify="center">
                <v-col>
                  <v-card>
                    <v-card-actions v-if="waiting">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-btn color="primary" @click="exportApplications">
                        <v-icon>mdi-export</v-icon> Export Applications
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="exportFilteredApplications"
                      >
                        <v-icon>mdi-checkbox-multiple-marked</v-icon>
                        Export Filtered Applications
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="exportReviews"
                        v-if="isAdmin"
                      >
                        <v-icon>mdi-export</v-icon> Export Reviews
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-2" dense align="center" justify="center">
                <v-col>
                  <v-autocomplete
                    label="筛选状态/Filter Status"
                    v-model="showStatuses"
                    multiple
                    :items="infoStatusSelect"
                    item-value="name"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        #default="{ active }"
                      >
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                            >{{ item.desc }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip
                        close
                        @click:close="
                          showStatuses = showStatuses.filter(
                            (s) => s != item.name
                          )
                        "
                      >
                        <v-icon>{{ infoStatusList[item.name].icon }} </v-icon>
                        {{ item.desc }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="筛选类型/Filter Type"
                    v-model="showTypes"
                    multiple
                    :items="infoTypeSelect"
                    item-value="name"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        #default="{ active }"
                      >
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon>
                              {{ infoTypeList[item.name].icon }}
                            </v-icon>
                            {{ item.desc }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip
                        close
                        @click:close="
                          showTypes = showTypes.filter((s) => s != item.name)
                        "
                      >
                        <v-icon>{{ infoTypeList[item.name].icon }} </v-icon>
                        {{ item.desc }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.review`]="{ item }" v-if="isProfessor">
              <template v-for="review of applicationReviews(item)">
                <v-tooltip
                  top
                  :key="review.id"
                  :color="reviewScoreColor(review.score)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="reviewScoreColor(review.score)"
                    >
                      mdi-numeric-{{ review.round }}-circle
                    </v-icon>
                  </template>
                  <span>
                    Round {{ review.round }}:
                    {{ reviewScoreDescription(review.score) }}
                  </span>
                </v-tooltip>
              </template>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="viewApplication(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View Application Detail</span>
              </v-tooltip>
              <v-tooltip top v-if="isAdmin">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="updateStatus(item)">
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Update Application Status</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import utils from "@/utils";

export default {
  data: () => ({
    search: "",
    waiting: false,
    infoStatus: "",
    applications: [],
    reviews: [],
    updateInfo: {},
    isAdmin: false,
    isProfessor: false,

    showStatuses: ["submitted"],
    infoStatusList: {
      empty: { desc: "empty", icon: "mdi-timer-sand-empty" },
      saved: { desc: "saved", icon: "mdi-content-save" },
      confirmed: { desc: "confirmed", icon: "mdi-account-check" },
      submitted: { desc: "submitted", icon: "mdi-check" },
      returned: { desc: "returned", icon: "mdi-recycle" },
      passed: { desc: "passed", icon: "mdi-flag" },
      failed: { desc: "failed", icon: "mdi-delete-circle" },
    },
    infoStatusSelect: [
      { desc: "empty", name: "empty" },
      { desc: "saved", name: "saved" },
      { desc: "confirmed", name: "confirmed" },
      { desc: "submitted", name: "submitted" },
      { desc: "returned", name: "returned" },
      { desc: "passed", name: "passed" },
      { desc: "failed", name: "failed" },
    ],

    showTypes: ["faculty"],
    infoTypeList: {
      faculty: { desc: "faculty", icon: "mdi-town-hall" },
      postdoc: { desc: "postdoc", icon: "mdi-school" },
    },
    infoTypeSelect: [
      { desc: "Faculty", name: "faculty" },
      { desc: "Postdoc", name: "postdoc" },
    ],

    info: [],
    updateStatusDialog: false,
  }),
  created() {
    this.isAdmin =
      this.$store.state.currentUser.role == "owner" ||
      this.$store.state.currentUser.role == "admin";
    this.isProfessor = this.$store.state.currentUser.role == "professor";
    this.getAllApplications();
  },
  computed: {
    infoHeaders() {
      return [
        { text: "Full Name", value: "full_name" },
        { text: "Chinese Name", value: "name_cn" },
        {
          text: "Type",
          value: "application_type",
          filter: (value) => this.showTypes.includes(value),
        },
        { text: "Position", value: "position_type" },
        {
          text: "Status",
          value: "status",
          filter: (value) => this.showStatuses.includes(value),
        },
        { text: "Letters", value: "letters" },
        { text: "Review", value: "review" },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  methods: {
    reviewScoreColor: utils.reviewScoreColor,
    reviewScoreDescription: utils.reviewScoreDescription,

    getAllApplications() {
      requests.get(`/api/review/applications`).then((res) => {
        this.applications = res.data.applications;
        this.reviews = res.data.reviews;
      });
    },

    applicationReviews(appl) {
      return this.reviews.filter((r) => r.application_id == appl.id);
    },

    updateStatus(item) {
      this.infoStatus = item.status;
      this.updateInfo = item;
      this.updateStatusDialog = true;
    },
    closeUpdateStatus() {
      this.updateStatusDialog = false;
      this.$nextTick(() => {
        this.infoStatus = "";
        this.updateInfo = {};
      });
    },
    handleStatusUpdate() {
      requests
        .post(
          `/api/admin/applications/${this.updateInfo.id}/status/${this.infoStatus}`,
          {}
        )
        .then(() => {
          this.getAllApplications();
          this.closeUpdateStatus();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    viewApplication(item) {
      window.open(`/review/detail/${item.id}`, "_blank").focus();
    },

    tokenFileDownload(role, token) {
      const url = `/api/${role}/download?token=${token}`;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportApplications() {
      this.waiting = true;
      requests
        .post(`/api/review/applications/export`)
        .then((res) => {
          this.tokenFileDownload("review", res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },
    exportFilteredApplications() {
      this.waiting = true;
      var ids = [];
      this.info.forEach((s) => {
        if (
          this.showStatuses.includes(s.status) &&
          this.showTypes.includes(s.application_type)
        ) {
          ids.push(s.id);
        }
      });
      this.requestSelectedExport(ids);
    },
    requestSelectedExport(ids) {
      requests
        .post(`/api/review/applications/export/select`, { ids: ids })
        .then((res) => {
          this.tokenFileDownload("review", res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },
    exportReviews() {
      this.waiting = true;
      requests
        .post(`/api/admin/reviews/export`)
        .then((res) => {
          this.tokenFileDownload("admin", res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },
  },
};
</script>
